footer{
    display: flex;
    width: 60%;
    justify-content: space-evenly;
    margin: 2em 0 2em 0;

}

.footer{
    &__link,
    &__signature{
        color: $colour-text;
        text-decoration: none;
        font-size: 1.25em;
        margin: 0;
    }
}


.newMemberEmbed{
    width: 100%;
    display: flex;
    flex-direction: column;
}
  
.notionNewMemberEmbed{
    min-width: 80%;
    margin: auto;
    margin-top: 4em;
    min-height: 100vh;
    border: none;
}
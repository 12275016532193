$ff-primary: 'Roboto', sans-serif;
$ff-secondary: 'Playfair Display', serif;
$ff-tertiary: 'Nanum Gothic', sans-serif;

$fs-h1: 3rem;
$fs-h2: 2.25rem;
$fs-h3: 1.25rem;
$fs-body: 1rem;

@media(min-width: 800px){
    $fs-h1: 3rem;
    $fs-h2: 2rem;
    $fs-h3: 1.5rem;
    $fs-body: 1.125rem;
}


body{
    font-family: $ff-primary;
}
.gamesExploreBar{

    display: flex;
    flex-direction: column;
    align-items: center;

    // &__title{
    //     font-weight: 400;
    //     letter-spacing: 0.20em;
    //     color: $colour-text;
    //     margin: 2em 0 1em 0;
    //     font-size: $fs-h2;
    // }

    // &__subtitle{
    //     margin: 0 0 2em 0;
    //     width: 35%;
    //     text-align: center;
    //     color: $colour-text;
    //     font-size: 1.25em;
    //     line-height: 2em;
    // }
}


.gameCard{
    width: 30em;
    height: 40em;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background-size: cover;
    transition: filter 200ms ease-in-out;
    filter: brightness(80%);
    

    &:hover,
    &:focus{
        cursor: pointer;
        filter: brightness(100%);
        box-shadow: (0.25em, 0.25em, 0.75em #91aab7);
    }

    &__container{
        display: flex;
        gap: 3em;
    }

    &__textContainer{
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
    }

    &__title{
        font-family: $ff-secondary;
        color: $colour-text;
        margin: 0;
    }

    &__subtitle{
        font-family: $ff-secondary;
        color: $colour-text;
        margin-bottom: 4em;
    }

    &__FFXIV{
        background-image: url("https://i.pinimg.com/originals/bc/1a/5a/bc1a5a5542e2f6b6ef64fc87d941aa98.jpg");
        &:hover{
            background-image: url("https://media4.giphy.com/media/MUzcUt3KPm4MQbxqLe/giphy.gif");
        }
    }
    &__ARK{
        background-position: center;
        background-image: url("https://steamuserimages-a.akamaihd.net/ugc/857222376536500731/C18ED7B6002792D6740F79923C2F93E3CD76A41C/?imw=1024&imh=578&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true");
        &:hover{
            background-image: url("https://giffiles.alphacoders.com/207/207618.gif");
        }
    }
    &__Destiny{
        background-position: center;
        background-image: url("https://twinfinite.net/wp-content/uploads/2023/02/Destiny-2-Lightfall-landscape.jpg?fit=1200%2C675");
        &:hover{
            background-image: url("https://media4.giphy.com/media/BI6l8dKAdjt1EOhOGV/giphy.gif?cid=6c09b95222cxqshiuf9dok82o83ujimqhxgncf5jbnt2v7tx&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=g");
        }
    }
}
.memberRoster{
    display: grid;
    max-width: 80%;
    margin: auto;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-gap: 2em;
    margin-top: 2em;
}


.memberPlate{
    border: solid white 2px;
    border-radius: 25px;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colour-text;
    background-color: $colour-primary;
    font-family: $ff-secondary;

    &__Text{
        display: flex;
        flex-direction: column;
        padding: 1em;
    }

    &__title{
        font-size: $fs-h2;
        margin: 0;
    }

    &__rank{
        font-size: $fs-h3;
        margin: 0;
    }

    &__rankLogo{
        width: 40%;
        border-radius: 50%;
    }
}

.guildedMemberRoster{
    display: grid;
    max-width: 80%;
    margin: auto;
    grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
    grid-gap: 2em;
    margin-top: 2em;
} 

.roster__section__title{
    text-align: center;
    font-size: $fs-h2;
    color: $colour-text;
}

.guildedMemberPlate{
    border-radius: 50%;
    aspect-ratio: 1/1;
    border: solid goldenrod 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.guilded__member__title{
    color: $colour-text;
}

.guilded__member__avatar{
    border-radius: 50%;
    max-width: 40%;
}
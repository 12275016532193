.homepage{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.heroBanner{
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $colour-primary;
}

.pageTitle{
    font-size: $fs-h1;
    font-family: $ff-primary;
    color: $colour-text;
}

.homepage__audacitylogo{
    max-width: 50%;
}

.homepage__main__section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
    margin-top: 5em;
    width: 100%;
    grid-area: main;
}

.homepage{
    &__sectionTitle{
        font-weight: 400;
        letter-spacing: 0.20em;
        color: $colour-text;
        margin: 2em 0 1em 0;
        font-size: $fs-h1;
    }

    &__sectionSubtitle{
        margin: 0 0 2em 0;
        width: 60%;
        text-align: center;
        color: $colour-text;
        font-size: 1.25em;
        line-height: 2em;
    }

    &__sectionLink{
        color: rgb(33, 99, 244);
        font-family: $ff-primary;
        font-weight: 800;
        font-size: large;
        text-decoration: none;
        margin: 4em 0 0 0;
    }
    
    &__carousel{
        width: 60%;

        &Image{
            width: 100%;
        }
    }
}
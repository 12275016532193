.navbar {
    padding: 1em;
    min-height: 6vh;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    width: 100%;
    transition: background-color 400ms ease-in-out;
    z-index: 3;
    grid-area: sidebar;

    &-scrolled{
        background-color: $colour-dark;
    }

    & li {
        text-decoration: none;
        list-style: none;

        &:hover .menu-sub{
            display: block;
            height: auto;
        }
    }

    &-ul{
        position: relative;
        display: flex;
        margin: auto;

        & a{
            padding: 1em;
            text-decoration: none;
            color: $colour-text;
            font-family: $ff-primary;
            font-size: $fs-h3;

            &:hover,
            &:focus{
                font-weight: $fw-bold;
                color: $colour-accent;
            }
        }
    }
  }


  .menu-sub{
    display: none;
    margin-top: 1em;
    position: absolute;
    background: rgba(0,0,0,0.301);
    backdrop-filter: blur(1rem);
    margin-right: 2em;
    z-index: 1000;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  

    & li{
        padding: 1em;

        & a:hover,
        & a:focus{
            color: white;
            cursor: pointer;
        }
    }

    & a{
        color: white;
    }


  }


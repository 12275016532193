//Colour Palette
$colour-primary:  rgba(178,161,77,1);
$colour-accent:  rgb(168, 152, 73);
$colour-text:  whitesmoke;
$colour-dark:  #1b1b1b;
$colour-light-fade: rgba(249, 224, 202,0.8);
$colour-light: rgb(249, 224, 202);

//Font weights
$fw-reg: 300;
$fw-bold: 900;


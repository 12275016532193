.standardButton{
    padding: 1em;
    background-color: $colour-light;
    border-radius: 25px;
    color: $colour-text;
    text-decoration: none;
    border: solid black 2px;
    max-width: 100%;
    text-align: center;
    font-size: 1em;

    &:hover,
    &focus{
        background-color: $colour-accent;
        cursor: pointer;
    }
}

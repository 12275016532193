.newsBar{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 4em 0 4em 0;
    align-items: center;

    &__textContainer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.newsCards__container{
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: center;
    gap: 4em;
}

.newsCard{

    max-width: 40%;
    color: $colour-text;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    transition: box-shadow 400ms ease-in-out;

    &:hover,
    &:focus{
        cursor: pointer;
        box-shadow: 0 0 50px $colour-accent;
    }

    &__image{
        max-width: 100%;
    }

    &__title{
        font-family: $ff-secondary;
        margin: 0 0 1em 0;
    }

    &__description{
        max-width: 80%;
        line-height: 2em;
    }
}

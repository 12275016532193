body {
  font-family: "Roboto", sans-serif;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: black;
}

.standardButton {
  padding: 1em;
  background-color: rgb(249, 224, 202);
  border-radius: 25px;
  color: whitesmoke;
  text-decoration: none;
  border: solid black 2px;
  max-width: 100%;
  text-align: center;
  font-size: 1em;
}
.standardButton:hover, .standardButtonfocus {
  background-color: rgb(168, 152, 73);
  cursor: pointer;
}

.navbar {
  padding: 1em;
  min-height: 6vh;
  display: flex;
  flex-direction: row;
  position: fixed;
  top: 0;
  width: 100%;
  transition: background-color 400ms ease-in-out;
  z-index: 3;
  grid-area: sidebar;
}
.navbar-scrolled {
  background-color: #1b1b1b;
}
.navbar li {
  text-decoration: none;
  list-style: none;
}
.navbar li:hover .menu-sub {
  display: block;
  height: auto;
}
.navbar-ul {
  position: relative;
  display: flex;
  margin: auto;
}
.navbar-ul a {
  padding: 1em;
  text-decoration: none;
  color: whitesmoke;
  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
}
.navbar-ul a:hover, .navbar-ul a:focus {
  font-weight: 900;
  color: rgb(168, 152, 73);
}

.menu-sub {
  display: none;
  margin-top: 1em;
  position: absolute;
  background: rgba(0, 0, 0, 0.301);
  backdrop-filter: blur(1rem);
  margin-right: 2em;
  z-index: 1000;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.menu-sub li {
  padding: 1em;
}
.menu-sub li a:hover, .menu-sub li a:focus {
  color: white;
  cursor: pointer;
}
.menu-sub a {
  color: white;
}

.gamesExploreBar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gameCard {
  width: 30em;
  height: 40em;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  background-size: cover;
  transition: filter 200ms ease-in-out;
  filter: brightness(80%);
}
.gameCard:hover, .gameCard:focus {
  cursor: pointer;
  filter: brightness(100%);
  box-shadow: 0.25em, 0.25em, 0.75em #91aab7;
}
.gameCard__container {
  display: flex;
  gap: 3em;
}
.gameCard__textContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
}
.gameCard__title {
  font-family: "Playfair Display", serif;
  color: whitesmoke;
  margin: 0;
}
.gameCard__subtitle {
  font-family: "Playfair Display", serif;
  color: whitesmoke;
  margin-bottom: 4em;
}
.gameCard__FFXIV {
  background-image: url("https://i.pinimg.com/originals/bc/1a/5a/bc1a5a5542e2f6b6ef64fc87d941aa98.jpg");
}
.gameCard__FFXIV:hover {
  background-image: url("https://media4.giphy.com/media/MUzcUt3KPm4MQbxqLe/giphy.gif");
}
.gameCard__ARK {
  background-position: center;
  background-image: url("https://steamuserimages-a.akamaihd.net/ugc/857222376536500731/C18ED7B6002792D6740F79923C2F93E3CD76A41C/?imw=1024&imh=578&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true");
}
.gameCard__ARK:hover {
  background-image: url("https://giffiles.alphacoders.com/207/207618.gif");
}
.gameCard__Destiny {
  background-position: center;
  background-image: url("https://twinfinite.net/wp-content/uploads/2023/02/Destiny-2-Lightfall-landscape.jpg?fit=1200%2C675");
}
.gameCard__Destiny:hover {
  background-image: url("https://media4.giphy.com/media/BI6l8dKAdjt1EOhOGV/giphy.gif?cid=6c09b95222cxqshiuf9dok82o83ujimqhxgncf5jbnt2v7tx&ep=v1_internal_gif_by_id&rid=giphy.gif&ct=g");
}

.newsBar {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 4em 0 4em 0;
  align-items: center;
}
.newsBar__textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsCards__container {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: center;
  gap: 4em;
}

.newsCard {
  max-width: 40%;
  color: whitesmoke;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: box-shadow 400ms ease-in-out;
}
.newsCard:hover, .newsCard:focus {
  cursor: pointer;
  box-shadow: 0 0 50px rgb(168, 152, 73);
}
.newsCard__image {
  max-width: 100%;
}
.newsCard__title {
  font-family: "Playfair Display", serif;
  margin: 0 0 1em 0;
}
.newsCard__description {
  max-width: 80%;
  line-height: 2em;
}

footer {
  display: flex;
  width: 60%;
  justify-content: space-evenly;
  margin: 2em 0 2em 0;
}

.footer__link, .footer__signature {
  color: whitesmoke;
  text-decoration: none;
  font-size: 1.25em;
  margin: 0;
}

.homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.heroBanner {
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(178, 161, 77);
}

.pageTitle {
  font-size: 3rem;
  font-family: "Roboto", sans-serif;
  color: whitesmoke;
}

.homepage__audacitylogo {
  max-width: 50%;
}

.homepage__main__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  margin-top: 5em;
  width: 100%;
  grid-area: main;
}

.homepage__sectionTitle {
  font-weight: 400;
  letter-spacing: 0.2em;
  color: whitesmoke;
  margin: 2em 0 1em 0;
  font-size: 3rem;
}
.homepage__sectionSubtitle {
  margin: 0 0 2em 0;
  width: 60%;
  text-align: center;
  color: whitesmoke;
  font-size: 1.25em;
  line-height: 2em;
}
.homepage__sectionLink {
  color: rgb(33, 99, 244);
  font-family: "Roboto", sans-serif;
  font-weight: 800;
  font-size: large;
  text-decoration: none;
  margin: 4em 0 0 0;
}
.homepage__carousel {
  width: 60%;
}
.homepage__carouselImage {
  width: 100%;
}

.newMemberEmbed {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notionNewMemberEmbed {
  min-width: 80%;
  margin: auto;
  margin-top: 4em;
  min-height: 100vh;
  border: none;
}

.memberRoster {
  display: grid;
  max-width: 80%;
  margin: auto;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  grid-gap: 2em;
  margin-top: 2em;
}

.memberPlate {
  border: solid white 2px;
  border-radius: 25px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: whitesmoke;
  background-color: rgb(178, 161, 77);
  font-family: "Playfair Display", serif;
}
.memberPlate__Text {
  display: flex;
  flex-direction: column;
  padding: 1em;
}
.memberPlate__title {
  font-size: 2.25rem;
  margin: 0;
}
.memberPlate__rank {
  font-size: 1.25rem;
  margin: 0;
}
.memberPlate__rankLogo {
  width: 40%;
  border-radius: 50%;
}

.guildedMemberRoster {
  display: grid;
  max-width: 80%;
  margin: auto;
  grid-template-columns: repeat(auto-fit, minmax(20em, 1fr));
  grid-gap: 2em;
  margin-top: 2em;
}

.roster__section__title {
  text-align: center;
  font-size: 2.25rem;
  color: whitesmoke;
}

.guildedMemberPlate {
  border-radius: 50%;
  aspect-ratio: 1/1;
  border: solid goldenrod 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.guilded__member__title {
  color: whitesmoke;
}

.guilded__member__avatar {
  border-radius: 50%;
  max-width: 40%;
}

